import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_FORM_QUERY} from "../Page/Page.query"
import Page from "../Page/Page";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles, createMuiTheme} from '@material-ui/core/styles';
import {CartContext} from "../../contexts/CartContext"
import {FormContext} from "../../contexts/FormContext"
import CheckoutPage from "../Checkout/Checkout";
import {ThemeProvider} from "@material-ui/styles";
import {StylesProvider} from '@material-ui/core/styles';
import {CardContent} from "@material-ui/core";
import {withRouter, Route, useParams, useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import DonationSetting from "../DonationSetting/DonationSetting";
import DonationSocialMediaShareBlock from "../DonationSocialMediaShareBlock/DonationSocialMediaShareBlock";
import * as ReactGA from "react-ga";

const FormData = () => {
    const cartContext = useContext(CartContext);
    const venue = window.location.pathname.split("/")[1]
    const form = window.location.pathname.split("/")[2]
    const { page } = useParams();
    const history = useHistory();
    const [productSelectionError, setProductSelectionError] = useState(false);
    const [cpm, setCpm] = useState(null);

    const handlePartialFormSubmission = async () => {
        let custref = cartContext.custref
        if (!cartContext.custref) {
            custref = uuidv4()
        }
        await fetch(process.env.REACT_APP_SUBMIT_URL + "/submit/partial", {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...formContext.formData,
                venue: venue,
                form: form,
                testMode: formContext.form.testMode,
                donationAmount: cartContext.donation,
                custref: custref,
                coveredCosts: (cartContext.donationSettings) ? cartContext.donationSettings.isSelected : false,
                donationSettings: cartContext.donationSettings,
                formId: formContext.formData.form.id,
                cpm: cpm,
            }),
            method: 'POST',
        }).then((res) => {
                console.log(res)
                if (res.ok) {
                    sendToTUNL()
                } else {
                    alert("There was an error. Please try again later.")
                }
            }
        )
    }

    const formContext = useContext(FormContext);
    const methods = useForm();
    const {handleSubmit} = methods;

    const {loading, error, data} = useQuery(GET_FORM_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            venue: venue,
            path: form
        },
    });

    const handleChange = event => {
        formContext.setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const onSubmit = formData => {
        if (isLastPage() && formContext.isDataCollectionFormType()) {
            handlePartialFormSubmission()
            return
        }

        if (!data || !data.forms || !data.forms[0]) {
            console.error('Form data not yet loaded');
            return;
        }

        const currentPageIndex = currentPage();
        const currentFormPage = data.forms[0].pages[currentPageIndex];

        let proceedToNextPage = true;

        if (currentFormPage.productsRequired) {
            const findProductBlocks = (blocks) => {
                let productBlocks = [];
                blocks.forEach(block => {
                    if (block.__typename === 'ComponentBlocksProductBlock') {
                        productBlocks.push(block);
                    }
                    if (block.__typename === 'ComponentBlocksTwoColumnBlock') {
                        if (block.two_column_block) {
                            // Check left column
                            if (block.two_column_block.leftColumn) {
                                productBlocks = productBlocks.concat(findProductBlocks(block.two_column_block.leftColumn));
                            }
                            // Check right column
                            if (block.two_column_block.rightColumn) {
                                productBlocks = productBlocks.concat(findProductBlocks(block.two_column_block.rightColumn));
                            }
                        }
                    }
                });
                return productBlocks;
            };

            const productBlocks = findProductBlocks(currentFormPage.blocks);
            let productIdsOnPage = [];

            // Get product IDs from cart
            const productsInCart = cartContext.products ? Object.keys(cartContext.products).filter(id => id !== 'form-fee') : [];

            console.log("found product blocks:", productBlocks);
            console.log("products in cart", productsInCart);

            productBlocks.forEach((block) => {
                if (block.products_block && block.products_block.products) {
                    const blockProductIds = block.products_block.products.map((product) => product.id.toString());
                    productIdsOnPage = productIdsOnPage.concat(blockProductIds);
                }
            });

            console.log("productIdsOnPage", productIdsOnPage);

            const productsSelectedOnPage = productIdsOnPage.some((id) => {
                const isIncluded = productsInCart.includes(id);
                console.log(`Checking if ${id} is in cart: ${isIncluded}`);
                return isIncluded;
            });
            console.log("productsSelectedOnPage result:", productsSelectedOnPage);

            if (!productsSelectedOnPage) {
                setProductSelectionError(true);
                proceedToNextPage = false;
            } else {
                setProductSelectionError(false);
            }
        }

        if (!proceedToNextPage) {
            return
        }

        history.push(`/${venue}/${form}/${(parseInt(currentPage()) + 1)}`);
    }

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: (data !== undefined) ? data.forms[0].venue.primaryColor : "#FF0000",
            },
            secondary: {
                main: (data !== undefined) ? data.forms[0].venue.secondaryColor : "#FF0000",
            },
        },
    });

    const useStyles = makeStyles((theme) => ({
        root: {
            margin: "auto",
            marginTop: 50,
            maxWidth: 1200,
        },
        backButton: {
            color: theme.palette.primary.light,
            position: "fixed"
        },
        nextButton: {
            marginTop: 20,
            marginBottom: 20
        },
        checkoutButton: {
            fontSize: "24px",
            right: 0,
            marginRight: 10,
            position: "absolute",
            textTransform: "none",
        },
        logo: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "-webkit-fill-available"
        },
        formName: {
            margin: "auto",
        },
        testModeBanner: {
            width: "100%",
            backgroundColor: "gray",
            margin: "auto",
            textAlign: "center",
            fontWeight: "bold"
        }
    }));

    const isLastPage = function () {
        return (parseInt(currentPage()) === data.forms[0].pages.length - 1);
    }

    const formHasMorePages = function () {
        return (parseInt(currentPage()) + 1 < data.forms[0].pages.length);
    }

    const isCheckoutPage = function () {
        return parseInt(currentPage()) === data.forms[0].pages.length;
    }

    const isSuccessPage = function () {
        return page === 'success'
    }

    const isFailurePage = function () {
        return page === 'failure'
    }

    const currentPage = function () {
        return (page !== undefined) ? page : 0;
    }

    const hasCheckoutBlock = function () {
        return data.forms[0].pages[currentPage()].blocks.filter(function (item) {
                return item.__typename === "ComponentBlocksCheckoutBlock";
            }
        ).length > 0
    }

    const isBlankPage = function () {
        return data.forms[0].formType !== "BlankPage"
    }

    const classes = useStyles(theme);

    const addLocalCSS = function (css) {
        const style = document.createElement('style');
        style.textContent = css;
        document.head.appendChild(style);
    }

    const addRemoteCSS = function (css) {
        const style = document.createElement('link');
        style.href = css;
        style.rel = "stylesheet"
        document.head.appendChild(style);
    }

    const addCSSWithID = function (id, css) {
        const style = document.createElement('style');
        style.id = id;
        style.textContent = css;
        document.head.appendChild(style);
    }

    const removeCSSWithID = function (id) {
        let child = document.getElementById(id)

        if (child) {
            document.head.removeChild(child)
        }
    }

    function updateCSS() {
        addLocalCSS(data.forms[0].venue.css)
        addLocalCSS(data.forms[0].css)
        removeCSSWithID('page-css')

        if (isFormPage() && data.forms[0].pages[currentPage()].css) {
            addCSSWithID('page-css', data.forms[0].pages[currentPage()].css)
        }
    }

    function isFormPage() {
        return !isCheckoutPage() && !isSuccessPage() && !isFailurePage()
    }

    const redirectAfterSuccess = () => {
        setTimeout(() => {
            if (data.forms[0].redirectToURL) {
                window.location.href = data.forms[0].redirectToURL
            }
        }, 30000);
    }

    useEffect(() => {
        if (isSuccessPage()) {
            redirectAfterSuccess()
        }
    }, [data]);

    useEffect(() => {
        if (!data) {
            return
        }
        ReactGA.initialize(data.forms[0].googleAnaltyicsId);
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log("GA pageview: " + data.forms[0].googleAnaltyicsId);
    }, [data])

    useEffect(() => {
        if (data && isCheckoutPage() && data.forms[0].skipShoppingCart && !cartContext.custref) {
            cartContext.setCustRef(uuidv4())
            return
        }
        if (data && isCheckoutPage() && data.forms[0].skipShoppingCart) {
            handlePartialFormSubmission()
        }
    }, [data, page, cartContext.custref])

    useEffect(() => {
        if (!data) {
            return;
        }

        let donationSettings = data.forms[0].donationSettings
        if (donationSettings) {
            cartContext.setDonationSettings(donationSettings)
        }

        let formFees = data.forms[0].form_fees
        formFees.forEach((fee) => {
            if (fee.type === "amount" && fee.applied_per !== "transaction") {
                cartContext.updateProduct("form-fee", fee.label, 1, fee.amount, null, -1, "")
            } else {
                cartContext.setTransactionFee(fee)
            }
        })
    }, [data])

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    function post(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage()])

    useEffect(() => {
        // Extract cpm parameter from URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const cpmParam = urlParams.get('cpm');
        if (cpmParam) {
            setCpm(cpmParam);
            // Store cpm in form data
            handleChange({
                target: {
                    name: "cpm",
                    value: cpmParam,
                }
            });
        }
    }, [])

    useEffect(() => {
        handleChange({
            target: {
                name: "products",
                value: cartContext.products,
            }
        });

        if (cartContext.transactionFee) {
            handleChange({
                target: {
                    name: "transactionFee",
                    value: cartContext.transactionFee,
                }
            });
        }

        handleChange({
            target: {
                name: "donationAmount",
                value: cartContext.donation,
            }
        })

        handleChange({
            target: {
                name: "transactionTotal",
                value: cartContext.calculateTotal(),
            }
        });

        handleChange({
            target: {
                name: "subtotal",
                value: cartContext.calculateSubtotal(),
            }
        });
    }, [cartContext]);

    const successURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/" + venue + "/" + form + "/success"
    }

    const webhookURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/webhook/success"
    }

    const failureURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/" + venue + "/" + form + "/failure"
    }

    function sendToTUNL() {
        if (formContext.isDataCollectionFormType()) {
            history.push("/" + venue + "/" + form + "/success")
            return;
        }

        if (isCheckoutPage() && data.forms[0].skipShoppingCart) {
            let amount = cartContext.calculateTotal();
            if (amount === 0) {
                alert("Please select a product or make a donation to continue. ")
                return false
            }
            post(formContext.tunlUrl(), {
                amount: amount,
                cardholdername: formContext.firstName + " " + formContext.lastName,
                street: formContext.currentBuyer()["address"],
                city: formContext.currentBuyer()["city"],
                state: formContext.currentBuyer()["region"],
                zip: formContext.currentBuyer()["zip"],
                country: formContext.currentBuyer()["country"],
                customer_email: formContext.currentBuyer()["email"],
                comments: form,
                custref: cartContext.custref,
                success_url: successURL(),
                webhook_url: webhookURL(),
                cancel_url: failureURL(),
                webhook_error_email: formContext.form.venue.webhookErrorEmail,
                hide_comments: (formContext.form.venue.hideComments === true) ? "1" : "0",
                hide_customer_email: (formContext.form.venue.hideCustomerEmail === true) ? "1" : "0",
                cpm: cpm,
            })
            return <div/>;
        }
    }

    function findDonationBlock() {
        let text = "";
        data.forms[0].pages.forEach((page) => {
            text = page.blocks.filter((block) => block.__typename === "ComponentBlocksDonationSocialMediaShareBlock")[0]
        })

        return text
    }

    if (data) {
        formContext.setForm(data.forms[0])

        updateCSS();

        if (data.forms[0].venue.remote_stylesheets) {
            data.forms[0].venue.remote_stylesheets.map(stylesheet => addRemoteCSS(stylesheet.url))
        }

        if (isCheckoutPage() && formContext.firstName === undefined) {
            history.push("/" + venue + "/" + form + "/" + (currentPage() - 1))
            return <div/>
        }

        if (isCheckoutPage() && data.forms[0].skipShoppingCart) {
            return (
                <CircularProgress/>
            )
        }

        return (
            <StylesProvider injectFirst>
                {data.forms[0].testMode &&
                <div id="testModeBanner" className={classes.testModeBanner}>
                    This form is in test mode.
                </div>
                }
                <ThemeProvider theme={theme}>
                    <div id="logo-container">
                        <a href={data.forms[0].venue.website}>
                            <img src={data.forms[0].venue.logo.url}
                                 id="logo"/>
                        </a>
                    </div>

                    <CardContent className={classes.root} id="card">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    {isSuccessPage() && (
                                        <>
                                        <div dangerouslySetInnerHTML={{__html: data.forms[0].successHTML}}/>

                                        {data.forms[0].showSocialMediaShareButtonsOnCheckout && (
                                            <DonationSocialMediaShareBlock sharedImage={findDonationBlock()?.sharedImage} sharedQuote={findDonationBlock()?.sharedQuote} headerText={findDonationBlock()?.headerText} />
                                        )}
                                        </>
                                    )}
                                    {isFailurePage() && (
                                        <div dangerouslySetInnerHTML={{__html: data.forms[0].failureHTML}}/>
                                    )}
                                    {isCheckoutPage() &&
                                    <CheckoutPage handleFormSubmission={handlePartialFormSubmission} form={methods}/>
                                    }
                                    {isFormPage() &&
                                    <Page key={data.forms[0].pages[currentPage()].id} form={methods}
                                          handleFormSubmission={handlePartialFormSubmission} handleChange={handleChange}
                                          page={data.forms[0].pages[currentPage()]}/>
                                    }
                                </Grid>
                            </Grid>

                            {productSelectionError && (
                              <div style={{ color: 'red', marginBottom: '1rem' }}>
                                Please select at least one product before proceeding.
                              </div>
                            )}

                            {formHasMorePages() &&
                            <Grid container justify="center">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  id="next-page"
                                  type="submit"
                                >
                                  Next
                                </Button>
                            </Grid>
                            }

                            {(cartContext.donationSettings && !isSuccessPage() && cartContext.donationSettings.placement === "bottomOfForm") && (
                                <DonationSetting
                                    donationSetting={cartContext.donationSettings}
                                />
                            )}

                            {isLastPage() && !hasCheckoutBlock() && isBlankPage() &&
                            <Grid container justify="center">
                                <Button className={classes.nextButton} size="small"
                                        color="secondary"
                                        id="checkout-button"
                                        type="submit">
                                    {formContext.isDataCollectionFormType() && (
                                        "Submit"
                                    )}
                                    {!formContext.isDataCollectionFormType() && (
                                        "Checkout"
                                    )}
                                </Button>
                            </Grid>
                            }
                        </form>
                    </CardContent>
                </ThemeProvider>
            </StylesProvider>
        )
    }

    return (
        <CircularProgress/>
    );
}

const Form = (props) => {
    return (
        <Route path={`/:venue/:form/:page?`} component={FormData}>
            <FormData venue={props.venue} form={props.form}/>
        </Route>
    )
};


export default withRouter(Form);
